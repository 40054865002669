import {useEffect, useState} from "react";
import {loginEmail, loginGoogle} from "../api/apiCall";
import {useHistory} from "react-router-dom";
import {GoogleLogin} from 'react-google-login';
import {Bars} from "react-loader-spinner";

const Login = () => {
    const alertClass = "flex items-center justify-center w-full lg:w-1/4 text-white p-3 rounded-md gap-x-6 absolute top-5";
    const [failed, setFailed] = useState(false);
    const [error, setError] = useState(false);
    const [noAccount, setNoAccount] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const [loadingLoginBtn, setLoadingLoginBtn] = useState(false);
    const [loadingGoogleBtn, setLoadingGoogleBtn] = useState(false);

    const emailLogin = () => {
        setLoadingLoginBtn(true)
        const loginData = {
            mail: email,
            password: password
        }
        loginEmail(loginData)
            .then(r => {
                if (r.status === 200) {
                    localStorage.setItem("loggedIn", "true")
                    localStorage.setItem("token", r.data.data.token)
                    history.push("/account")
                } else {
                    setFailed(true)
                    setTimeout(() => setFailed(false), 3000)
                }
            })
            .catch(error => {
                setError(true)
                setTimeout(() => setError(false), 3000)
            })
            .finally(() => setLoadingLoginBtn(false))
    }
    const signOut = () => {
        if (window.gapi) {
            const authInstance = window.gapi.auth2.getAuthInstance()
            if (authInstance != null) {
                authInstance.signOut().then(authInstance.disconnect())
            }
        }
    };

    const onGoogleSignIn = (responseGoogle) => {
        setLoadingGoogleBtn(false)
        let profile = responseGoogle.getBasicProfile();
        const loginData = {
            "googleId": profile.getId(),
            "facebookId": 0,
            "mail": profile.getEmail(),
            "name": profile.getGivenName(),
            "ppUrl": profile.getImageUrl(),
            "socialType": 0,
            "surname": profile.getFamilyName()
        }
        loginGoogle(JSON.stringify(loginData))
            .then(r => {
                if (r?.status === 200) {
                    localStorage.setItem("loggedIn", "true")
                    localStorage.setItem("userData", JSON.stringify(r.data.data))
                    localStorage.setItem("token", r.data.data.token)
                    history.push("/account")
                } else {
                    setNoAccount(true)
                    setTimeout(() => setNoAccount(false), 3000)
                }
            })
            .catch(error => {
                console.log(error)
                setError(true)
                setTimeout(() => setError(false), 3000)
            })
            .finally(() => setLoadingGoogleBtn(false));
    }

    useEffect(() => {
        if (localStorage.getItem("loggedIn") !== "true") {
            signOut();
        } else if (localStorage.getItem("loggedIn") === "true" && localStorage.getItem("token")) {
            history.push("/account")
        } else {
            localStorage.clear()
            signOut()
        }
    }, [history]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('deleted') && searchParams.get('deleted').trim() === "true") {
            setTimeout(() => setDeleted(false), 3000)
            setDeleted(true)
        }
    }, []);
    return (
        <div className="flex flex-col justify-center items-center h-screen p-4 lg:p-0 dark">
            {failed ?
                <div className={alertClass + " bg-red-600"}>
                    <p>E-mail yada şifrenizi hatalı girdiniz </p>
                    <span className="cursor-pointer text-xl">x</span>
                </div> : ""}
            {noAccount ?
                <div className={alertClass + " bg-red-600"}>
                    <p>Hesap bulunamadı</p>
                    <span className="cursor-pointer text-xl">x</span>
                </div> : ""}
            {error ?
                <div className={alertClass + " bg-red-600"}>
                    <p>Bağlantı hatası</p>
                </div> : ""}

            {deleted ?
                <div id="deleted" className={alertClass + " bg-green-600"}>
                    <p>Hesap Başarıyla Silindi</p>
                </div> : ""}

            <div
                className="flex flex-col justify-center items-center w-full lg:w-1/4 gap-y-8 p-4 lg:p-8 shadow-lg rounded-md">
                <img src="/icon.png" width={"96"} height={"96"} alt="logo"/>
                <div className="w-full text-center">
                    <h2 className="text-2xl">Giriş Yap</h2>
                </div>
                <div className="w-full">
                    <label> E-posta adresi
                        <input className="username outline-blue-500 h-10 w-full px-4 rounded border" type="text"
                               onChange={(e) => setEmail(e.target.value)}
                               placeholder="E-posta adresi"/>
                    </label>
                </div>
                <div className="w-full">
                    <label> Parola
                        <input className="password outline-blue-500 h-10 w-full px-4 rounded border" type="password"
                               onChange={(e) => setPassword(e.target.value)}
                               placeholder="Parola"/>
                    </label>
                </div>
                <div className={loadingLoginBtn ? "w-full flex flex justify-center" : "w-full flex justify-end"}>
                    {
                        loadingLoginBtn ?
                            <Bars
                                height={"50px"}
                                color="#4fa94d"
                                ariaLabel="bars-loading"
                                visible={true}
                            /> :
                            <button
                                onClick={emailLogin}
                                className="border border-blue-700 bg-blue-600 text-white w-full shadow-md rounded-md px-4 py-2">
                                Giriş Yap
                            </button>
                    }
                </div>
                <div className="flex justify-center items-center w-full gap-x-2">
                    <div className="w-full h-px bg-gray-500"></div>
                    <span className="shrink-0">ya da</span>
                    <div className="w-full h-px bg-gray-500"></div>
                </div>
                <div className="w-full">
                    {
                        loadingGoogleBtn ? <Bars
                                height={"50px"}
                                color="#4fa94d"
                                ariaLabel="bars-loading"
                                wrapperClass={"justify-center"}
                                visible={true}
                            /> :
                            <GoogleLogin
                                className={"googleSignInButton"}
                                buttonText={"Google ile oturum açın"}
                                clientId="320162464329-4de6tik85psbp682s8auljl22tviq7h8.apps.googleusercontent.com"
                                onSuccess={onGoogleSignIn}
                                onFailure={(error) => {
                                    setError(true)
                                    setTimeout(() => setError(false), 3000)
                                    console.log(error)
                                    setLoadingGoogleBtn(false)
                                }}
                                onRequest={() => setLoadingGoogleBtn(true)}
                                cookiePolicy={'single_host_origin'}
                            />
                    }
                    <div className="g-signin222" data-height="50" data-longtitle="true"
                         data-onsuccess="onSignIn"></div>
                </div>
            </div>
        </div>
    )
}

export default Login