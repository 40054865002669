import './App.css';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import AppRoute from "./component/AppRoute";
import ProfilePage from "./pages/ProfilePage";
import Landing from "./component/Landing";

function App() {
    return (
        <Router>
            <Switch>
                <AppRoute exact={true} path={"/"} component={Landing}/>
                <AppRoute exact={true} path={"/account"} component={ProfilePage}/>
            </Switch>
        </Router>
    );
}

export default App;
