import axios from "axios";

const base_url = "https://api.qupic.net/api/v1";

axios.interceptors.request.use(request => {
    request.headers.setContentType("application/json")
    return request;
})

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        //localStorage.clear();
        window.location.replace("/")
        window.location.reload();
    }
    return error;
});

export const loginEmail = (creds) => {
    return axios.post(base_url + '/login', creds);
};

export const loginGoogle = (creds) => {
    return axios.post(base_url + '/login/socialAccount', creds);
};

export const deleteProfile = () => {
    return axios.delete(base_url + '/userProfile', {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    });
};

export const userDetails = () => {
    return axios.get(base_url + '/userProfile/details', {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    });
};