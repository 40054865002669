import {useEffect, useState} from "react";
import ProfilePage from "../pages/ProfilePage";
import Login from "../pages/Login";

const Landing = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const data = localStorage.getItem('loggedIn');
        data === "true" ? setLoggedIn(true) : localStorage.setItem('loggedIn', "false")
    }, [loggedIn]);


    return (
        <div className="dark">
            {loggedIn ? <ProfilePage/> : <Login/>}
        </div>
    )
}
export default Landing