import {useEffect, useState} from "react";
import {deleteProfile, userDetails} from "../api/apiCall";
import {useHistory} from "react-router-dom";
import {ThreeDots} from "react-loader-spinner";

const ProfilePage = () => {

    const history = useHistory()
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const afterDeleteCleanUp = () => {
        localStorage.clear()
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    const deleteAccount = () => {
        deleteProfile()
            .then(r => {
                if (r?.status === 200) {
                    afterDeleteCleanUp()
                    history.push("/?deleted=true")
                } else {
                    setError(true)
                    setTimeout(() => setError(false), 3000)
                }
            })

    }
    const logout = () => {
        localStorage.clear()
        history.push("/")
    }
    const toggleModal = () => {
        document.getElementById('modal').classList.toggle('hidden')
    }

    useEffect(() => {
        let linkElement = document.createElement("link");
        linkElement.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
        linkElement.rel = "stylesheet"
        linkElement.referrerpolicy = "no-referrer"
        linkElement.crossOrigin = "anonymous"
        document.head.appendChild(linkElement)

        if (localStorage.getItem('loggedIn') === "true" && localStorage.getItem('token') !== undefined) {
            userDetails()
                .then(r => {
                    setData(r.data.data)
                    setTimeout(() => setLoading(false), 500)
                })
        } else {
            window.location.href = "/"
        }
    }, [loading]);
    return (
        <div>
            {loading ?
                <div className="loading-ball-container">
                    <ThreeDots
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperClass="loading-ball"
                        visible={true}
                    />
                </div> :
                <div className="dark">
                    <div className="flex justify-center items-center h-screen flex-col !mx-4 !sm:mx-0">
                        {
                            error ?
                                <div className="flex justify-center rounded">
                                    <div
                                        className="flex items-center !my-10 justify-center sm:w-1/4 text-white p-3 rounded-md gap-x-6 absolute top-5 bg-red-600">
                                        <p>Bağlantı hatası</p>
                                    </div>
                                </div> : ""
                        }
                        <div
                            className="flex flex-col rounded-lg sm:w-1/4 shadow-xl border border-gray-100 justify-center items-center w-full">
                            <div className="w-full flex items-center gap-x-4 p-4 user-info-div">
                                <div className="justify-start">
                                    <img src={data?.ppUrl ? data?.ppUrl : "/icon.png"} width="45" height="45"
                                         className="user-profile-image"
                                         alt="QupicPP"/>
                                </div>
                                <div>
                                    <div className="flex flex-1 capitalize gap-x-1 font-medium text-lg">
                                        <p>{data?.name}</p>
                                        <p>{data?.surname}</p>
                                    </div>
                                    <div className="text-sm">{data?.mail}</div>
                                </div>
                                <div style={{marginLeft:"auto"}}>
                                    <button id="signOutButton" className="hover:text-red-600">
                                        <i className="fa-solid fa-arrow-right-from-bracket fa-xl" onClick={logout}></i>
                                    </button>
                                </div>
                            </div>
                            <div className="w-full flex flex-col justify-center gap-2 px-4">
                                <table className="table-auto">
                                    <tbody>
                                    <tr>
                                        <td>Kayıt Tarihi</td>
                                        <td className="text-right">{data?.createdAt.replace("GMT","")}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-full flex flex-col justify-center gap-2 px-4 py-2">
                                <table className="table-auto">
                                    <tbody>
                                    <tr>
                                        <td>Yapılan Yorum:</td>
                                        <td>{data?.totalComment}</td>
                                    </tr>
                                    <tr>
                                        <td>Okunan/Okunacak Kitap:</td>
                                        <td>{data?.totalRead}</td>
                                    </tr>
                                    <tr>
                                        <td>Kullanılan Oy:</td>
                                        <td>{data?.totalVote}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-center items-center gap-x-4 p-4 flex-col w-full">
                                <button
                                    className="mt-2 border border-red-700 bg-red-600 text-white shadow-md rounded-md px-4 py-2 w-full"
                                    onClick={toggleModal}>Hesabımı Sil
                                </button>
                                <small className="text-red-600 mt-2">*Bu işlem geri alınamaz!</small>
                            </div>
                        </div>
                    </div>

                    <div className="fixed z-10 overflow-y-auto w-full left-0 hidden sm:top-0 top-[33%]" id="modal">
                        <div
                            className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity">
                                <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                            </div>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                            <div role="dialog"
                                 className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md"
                                 aria-modal="true"
                                 aria-labelledby="modal-headline">
                                <div className="!py-8 bg-white text-center !m-auto w-2/3">
                                    <span>Hesabınızı silmek istediğinize emin misiniz?</span>
                                </div>
                                <div className="px-4 py-3 flex justify-end gap-x-6 mb-4">
                                    <button onClick={toggleModal}
                                            className="py-2 px-4 bg-white border border-gray-300 text-gray-900 rounded mr-2 w-2/3"
                                            type="button">Hayır
                                    </button>
                                    <button onClick={deleteAccount}
                                            className="py-2 px-4 border border-red-700 bg-red-500 text-white rounded mr-2 w-2/3"
                                            type="button">Evet
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProfilePage;